import { Product, Subscription, Prisma } from '@prisma/client'

export const validSubscriptionFilter: Prisma.SubscriptionFindManyArgs['where'] =
    {
        status: {
            in: ['active', 'trialing', 'past_due'],
        },
    }

export const orgWithSubscriptionFilter: Prisma.OrgFindManyArgs['where'] = {
    OR: [
        {
            subscriptions: {
                some: {
                    status: {
                        in: ['active', 'trialing', 'past_due'],
                    },
                },
            },
        },
        {
            createdAt: {
                gte: nDaysAgo(7),
            },
        },
    ],
}

function nDaysAgo(n: number) {
    const d = new Date()
    d.setDate(d.getDate() - n)
    return d
}

type PlanConfig = {
    name: string
    productId: string
    isLifetime?: boolean
    hidden?: boolean
    description: string
    limits: Limits
}

export type Limits = {
    maxEmails: number
    maxUsersNumber: number

    // canInviteUsers?: boolean
}

export const limitNamesMapper: Record<keyof Limits, string> = {
    maxEmails: 'Emails per month',
    maxUsersNumber: 'Max members',
}

export const limitNames: (keyof Limits)[] = Object.keys(limitNamesMapper) as any

export function betterLimitName(name: string): string {
    return limitNamesMapper[name]
}

if (!process.env.NEXT_PUBLIC_ENV) {
    throw new Error('NEXT_PUBLIC_ENV is not set')
}
const isProd = process.env.NEXT_PUBLIC_ENV === 'production'
const isSeeding = !!process.env.SEEDING

export const FREE_TRIAL_DAYS = 7

// generated from seed-stripe.ts
const prodProductIds = {
    '500': 'prod_MdZXQm0tcdKSwA',
    '5k': 'prod_MdZXt0vTwGS6Kc',
    '10k': 'prod_MdZXvtvyLIDurS',
    '50k': 'prod_MdZX5KqgEwn7wo',
    '100k': 'prod_MdZXOoxlBfQcR9',
    '300k': 'prod_MdZXfdM63qCkue',
    '600k': 'prod_MdZXiaucou4ERn',
    '1M': 'prod_MdZXGxk2ewQyP5',
}
const devProductIds = {
    '500': 'prod_Ma83sV8tBzqSF8',
    '5k': 'prod_Ma83k6O237WBf5',
    '10k': 'prod_Ma83xxa2P2zqlP',
    '50k': 'prod_Ma84wqAx42i9Y7',
    '100k': 'prod_Ma84Be0vpG0aMz',
    '300k': 'prod_Ma84mMZbRqKNM9',
    '600k': 'prod_Ma845fGSbJYxNl',
    '1M': 'prod_Ma84fp1gdDbSb1',
}

// TODO put the prod emails price id in data file
export const internalEmailPriceId = isProd
    ? 'price_1M4Qz3DRDqyAciXXAj14IflA'
    : 'price_1M46oGDRDqyAciXXXWOZolY9'

export const subscriptionPlansConfig: PlanConfig[] = [
    {
        limits: {
            maxEmails: 500,
            maxUsersNumber: 1,
            //
        },
        name: '500',
        description: '500 emails',
    },
    {
        limits: {
            maxEmails: 5_000,
            //
            maxUsersNumber: 3,
        },
        name: '5k',
        description: '5k emails',
    },
    {
        limits: {
            maxEmails: 10_000,
            //
            maxUsersNumber: 5,
        },
        name: '10k',
        description: '10k emails',
    },
    {
        limits: {
            maxEmails: 50_000,
            //
            maxUsersNumber: 10,
        },
        name: '50k',
        description: '50k emails',
    },
    {
        limits: {
            maxEmails: 100_000,
            //
            maxUsersNumber: 10,
        },
        name: '100k',
        description: '100k emails',
    },
    {
        limits: {
            maxEmails: 300_000,
            //
            maxUsersNumber: 10,
        },
        name: '300k',
        description: '300k emails',
    },
    {
        limits: {
            maxEmails: 600_000,
            //
            maxUsersNumber: 10,
        },
        name: '600k',
        description: '600k emails',
    },
    {
        limits: {
            maxEmails: 1_000_000,
            //
            maxUsersNumber: 10,
        },
        name: '1M',
        description: '1M emails',
    },
].map((x) => {
    const productId = isProd ? prodProductIds[x.name] : devProductIds[x.name]
    if (!isSeeding && !productId) {
        throw new Error(`Product id for ${x.name} is not present`)
    }
    return {
        ...x,
        productId,
    }
})

export const FREE_TRIAL_PLAN_ID = subscriptionPlansConfig.find(
    (x) => x.name === '500',
)!.productId

if (!isSeeding && !FREE_TRIAL_PLAN_ID) {
    throw new Error('FREE_TRIAL_PLAN_ID is not set')
}

export function subscriptionSorter(a: Subscription, b: Subscription) {
    const prodA = getPlanConfig({ productId: a.productId })
    const prodB = getPlanConfig({ productId: b.productId })
    if (prodA && prodB) {
        return prodB?.limits?.maxEmails - prodA?.limits?.maxEmails
    }
    return 0
}

export function getUsageWindowStartDate() {
    const now = new Date()
    const firstDay = new Date(now.getFullYear(), now.getMonth(), 1)
    return firstDay
}

export function getPlanConfig(p: { productId: string }) {
    const productId = p?.productId
    const plan = subscriptionPlansConfig.find((p) => p.productId === productId)
    // if (productId && !plan && !p.isSandbox) {
    //     throw new Error(`No max emails found for plan ${productId}`)
    // }
    if (!plan) {
        return null
    }

    return plan
}

// problem: if i create upsells for stuff like 1 more domain, i need to compute the sum of the numbers instead of taking the bigger one
// what if i always make the sum if one limit is a number?

// problem: if limits are connected to subscription every time i want to make users upsell i need to create new plans
// this makes sense if i want to increase prices
// this makes sense for lifetime deals after camp end

export function mergePlanLimits(productIds: string[]) {
    if (!productIds) {
        productIds = []
    }
    const limitsArr = productIds.map(
        (x) => getPlanConfig({ productId: x })?.limits,
    )

    // add free plan if no subscription
    // limitsArr.push(getPlanConfig({ productId: isProd ? '' : ''

    const mergedLimits: Partial<Limits> = Object.fromEntries(
        limitNames.map((k) => {
            return [
                k,
                limitsArr
                    .filter(Boolean)
                    .map((x) => x![k])
                    .reduce((a, b) => {
                        if (typeof a === 'number' && typeof b === 'number') {
                            return (a || 0) + (b || 0)
                        }
                        if (typeof a === 'boolean' || typeof b === 'boolean') {
                            return a || b
                        }
                        return a
                    }, {}),
            ]
        }),
    )
    return mergedLimits
}

export type DkimSettings = {
    /** is the domain name to use in the signature */
    domainName: string
    /** is the DKIM key selector */
    keySelector: string
    /** is the private key for the selector in PEM format */
    privateKey: string
    publicKey: string
}

export const salespackEmailsDomain = 'spackemail.com'
