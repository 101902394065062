import NextLink from 'next/link'
import { DashboardHeader } from 'beskar/src/Header'
import {
    Button,
    Faq,
    FeedbackWithCrisp,
    Footer,
    Link,
    NavBar,
    PageContainer,
    Pricing,
    PricingProps,
    useColorMode,
    useColorModeValue,
    useThrowingFn,
} from 'beskar/landing'
import {
    MoonIcon,
    SunIcon,
    CogIcon,
    LogoutIcon,
    CreditCardIcon,
} from '@heroicons/react/outline'
import { Avatar } from '@nextui-org/react'

import { signIn, signOut, useSession } from 'next-auth/react'
import { useRouter } from 'next/router'
import { AvatarButton } from 'beskar/src/Header'
import {
    PricingSlider,
    PricingSliderProps,
} from 'beskar/src/landing/PricingSlider'
import { DropDownMenu } from 'beskar/src/DropDown'
import { SelectOrg } from './SelectOrg'
import { getSubscription } from '@app/pages/api/functions'
import { SVGProps } from 'react'
import classNames from 'classnames'
import { getStripe, refreshSsr } from '@app/utils'
import useSWR from 'swr'
import { Faded } from 'baby-i-am-faded'
import { useUpdateAtom } from 'jotai/utils'
import { atomUpgradeModal } from '@app/utils/atoms'
import {
    createStripeCheckoutSession,
    createStripePortal,
} from '@app/pages/api/stripe'
import { Subscription } from 'db'
import { subscriptionPlansConfig } from 'db/data'
import { ChevronLeftIcon } from '@heroicons/react/solid'

export function MyHeader({}) {
    const router = useRouter()

    const showBackButton = !!router.query.campId
    return (
        <DashboardHeader
            logo={
                <div className='flex flex-col gap-3 '>
                    {showBackButton ? (
                        <BackToOrg name={''} />
                    ) : (
                        <Logo className='!text-3xl ' />
                    )}
                    <div className='flex gap-3 items-center'>
                        <SelectOrg
                            // there is no site in user settings, just keep loading state
                            doNotRedirect={
                                router.asPath === '/board/settings' ||
                                router.asPath === '/new' ||
                                router.asPath.startsWith('/upgrade')
                            }
                        />
                        <FreeTrialRemaining className='hidden md:block' />
                    </div>
                </div>
            }
            links={
                <>
                    <Link href='/blog'>Blog</Link>
                    <Link href='/home'>Home</Link>
                    <FeedbackWithCrisp useDefaultComponent />
                </>
            }
            menu={<AvatarMenu />}
        />
    )
}

function BackToOrg({ name = '' }) {
    const router = useRouter()
    const orgId = router.query.orgId as string
    return (
        <div className='flex items-center gap-3'>
            <Button
                icon={<ChevronLeftIcon className='h-5' />}
                bg='gray.100'
                bgDark='gray.800'
                className='opacity-70 font-medium text-sm'
                href={`/board/org/${orgId}`}
            >
                Other campaigns
            </Button>
            {name && <div className='text-xl opacity-70'>{name}</div>}
        </div>
    )
}

function FreeTrialRemaining({ className = '' }) {
    const router = useRouter()
    const orgId = router.query.orgId as string

    const { data, error, isValidating } = useSWR(
        ['getSub', orgId],
        (_, orgId) => getSubscription({ orgId }),
    )
    const updateUpgradeModal = useUpdateAtom(atomUpgradeModal)
    if (!orgId) {
        return null
    }
    if (!data) {
        return null
    }
    // show 0 trial days even if user unsubscribed or trial ended
    if (!data.subscription) {
        const days = data.trialDaysRemaining
        return (
            <Faded animationName='simpleFadeIn'>
                <style jsx>{`
                    @keyframes simpleFadeIn {
                        from {
                            opacity: 0;
                        }
                        to {
                            opacity: 1;
                        }
                    }
                `}</style>
                <Button
                    onClick={() => {
                        updateUpgradeModal({
                            isOpen: true,
                            reason: 'Upgrade',
                            // orgId: orgId,
                        })
                    }}
                    ghost
                    className={classNames(
                        'text-sm truncate capitalize opacity-70 font-medium',
                        className,
                    )}
                >
                    {days} trial days left
                </Button>
            </Faded>
        )
    }
}

export function MyNavbar({ canLogout = false }) {
    return (
        <NavBar
            logo={<Logo />}
            navs={
                <>
                    <Link href={'/blog'}>Blog</Link>
                    <Link
                        href='https://changelog.salespack.io'
                        target={'_blank'}
                    >
                        Changelog
                    </Link>
                    {canLogout ? (
                        <Link
                            href=''
                            onClick={(e) => {
                                e.preventDefault()
                                signOut()
                            }}
                        >
                            Sign Out
                        </Link>
                    ) : (
                        <LoginLink />
                    )}
                </>
            }
        />
    )
}

export function MyFooter({}) {
    return (
        <Footer
            // justifyAround
            columns={{
                Company: (
                    <>
                        <Link href='/blog'>Blog</Link>
                        {/* <Link href='/docs/company/refund-policy'>
                            Refund Policy
                        </Link> */}
                    </>
                ),
                'Who made this?': (
                    <>
                        <Link href='https://twitter.com/__morse'>
                            My Twitter
                        </Link>
                        <Link href='mailto:tommy@salespack.io'>Contact me</Link>
                    </>
                ),
            }}
        />
    )
}

function AvatarMenu({ imgSrc = '' }) {
    const { toggleColorMode, isDark } = useColorMode()
    const router = useRouter()
    const { data: session } = useSession()

    let avatar = (
        <div className=''>
            <AvatarButton
                // squared
                // textColor={'white'}
                // color='gradient'
                className={'border'}
                name={session?.user?.name}
            />
        </div>
    )

    return (
        <DropDownMenu button={avatar}>
            <DropDownMenu.Item
                onClick={toggleColorMode}
                icon={useColorModeValue(
                    <MoonIcon className='w-5 h-5 opacity-60' />,
                    <SunIcon className='w-5 h-5 opacity-60' />,
                )}
            >
                {!isDark ? 'Dark mode' : 'Light Mode'}
            </DropDownMenu.Item>
            <NextLink legacyBehavior href={`/board/settings`}>
                <DropDownMenu.Item
                    icon={<CogIcon className='w-5 h-5 opacity-60' />}
                >
                    Settings
                </DropDownMenu.Item>
            </NextLink>
            <DropDownMenu.Item
                onClick={() => signOut({ callbackUrl: '/' })}
                icon={<LogoutIcon className='w-5 h-5 opacity-60' />}
            >
                Sign out
            </DropDownMenu.Item>
        </DropDownMenu>
    )
}

export function Logo({ className = '' }) {
    // const { status } = useSession()
    return (
        <NextLink legacyBehavior href={'/'} passHref>
            <a
                className={classNames(
                    'text-4xl space-x-3 items-center font-medium',
                    'flex',
                    className,
                )}
            >
                <LogoIcon className='h-[24px]' />
                <div className=''>Salespack</div>
            </a>
        </NextLink>
    )
}

function LogoIcon({ ...rest }) {
    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            width={30}
            height={32}
            viewBox='0 0 30 32'
            {...rest}
        >
            <path
                fill='currentColor'
                d='M9.9,29.7840402 L0.93,13.9973735 C0.345,13.0221354 0,11.9402307 0,10.7364211 C0.00276767676,7.67006673 2.24801082,5.08341757 5.24237876,4.69689945 C8.2367467,4.31038133 11.0474327,6.24440255 11.805,9.21261161 L18,9.21261161 L18,5.4030878 C18,3.72689732 19.35,2.35546875 21,2.35546875 L21,6.30213542 L23.385,3.87927827 L30,3.87927827 L30,6.92689732 L24.615,6.92689732 L21,10.5992783 L21,10.873564 L24.615,14.5459449 L30,14.5459449 L30,17.593564 L23.385,17.593564 L21,15.1707068 L21,19.1173735 C19.3431458,19.1173735 18,17.752908 18,16.0697545 L18,12.2602307 L11.805,12.2602307 C11.655,12.8545164 11.415,13.4183259 11.115,13.9364211 L20.115,29.7840402 L27,29.7840402 C28.6568542,29.7840402 30,31.1485057 30,32.8316592 L30,34.3554688 L0,34.3554688 L0,32.8316592 C0,31.1554687 1.35,29.7840402 3,29.7840402 L9.9,29.7840402 Z M8.865,16.0697545 C8.025,16.5573735 7.05,16.8316592 6,16.8316592 L13.365,29.7840402 L16.65,29.7840402 L8.865,16.0697545 M6,7.68880208 C4.34314575,7.68880208 3,9.05326761 3,10.7364211 C3,12.4278497 4.335,13.7840402 6,13.7840402 C7.665,13.7840402 9,12.4278497 9,10.7364211 C9,9.05326761 7.65685425,7.68880208 6,7.68880208 Z'
                transform='translate(0 -2.355)'
            />
        </svg>
    )
}

export function LoginLink({}) {
    const { status } = useSession()
    const router = useRouter()
    return (
        <div key={status} className='max-w-[14ch] text-left md:text-center'>
            {status === 'authenticated' ? (
                <Link onClick={() => router.push('/board')}>
                    Go to Dashboard
                </Link>
            ) : (
                <Link
                    data-name='login'
                    onClick={() =>
                        signIn(
                            undefined,
                            {
                                callbackUrl: '/board',
                                redirect: true,
                            },
                            { prompt: 'select_account' },
                        )
                    }
                >
                    Login or Sign Up
                </Link>
            )}
        </div>
    )
}

const CONTACT_US_LINK = 'mailto:tommy@salespack.io'

const lifetimeProductIds = subscriptionPlansConfig
    .filter((x) => x.isLifetime)
    .map((x) => x.productId)

export function MyPricing({
    onCheckout,
    orgId: orgId,
    ...rest
}: Partial<PricingSliderProps> & { orgId: string }) {
    const { fn: createPortalClient, isLoading: isLoadingPortal } =
        useThrowingFn({
            fn: async () => {
                if (!orgId) {
                    window.location.href = `/board`
                    return {
                        skipToast: true,
                    }
                }
                const { url } = await createStripePortal({
                    orgId: orgId,
                })
                window.location.href = url
                return {
                    skipToast: true,
                }
            },
        })
    const router = useRouter()
    return (
        <PricingSlider
            {...{
                products: [],
                allowYearlyBilling: false,
                features: [
                    'Unlimited email accounts',
                    'Priority Support',
                    'Email warmup',
                    // 'Password protection',
                    // 'Password protection',
                    // 'Password protection',
                ],
                needMoreEmail: 'tommy@salespack.io',
                trialDays: 7,
                async getSubscription() {
                    const d = await getSubscription({ orgId: orgId })

                    if (
                        d?.subscription?.productId &&
                        lifetimeProductIds.includes(d?.subscription?.productId)
                    ) {
                        // lifetime deals cannot be upgraded
                        return null
                    }
                    let sub: Subscription = d?.subscription
                    if (!sub) {
                        return null
                    }
                    return {
                        id: sub?.id,
                        productId: sub?.priceId,
                        unit_price: sub?.unit_amount,
                    }
                },

                async updatePlan({ planId, subscriptionId }) {
                    await createPortalClient()
                },

                manageSubscriptionHandler: createPortalClient,
                async onCheckout(arg) {
                    const { sessionId } = await createStripeCheckoutSession({
                        orgId: orgId,
                        priceId: arg.productId, // productId here means price id
                    })

                    const stripe = await getStripe()
                    await stripe?.redirectToCheckout({ sessionId })

                    // onCheckout && onCheckout(arg)
                },
                ...rest,
            }}
        />
    )
}

export function MyFaq({ className = '' }) {
    return (
        <PageContainer
            className={classNames(
                'max-w-5xl px-4 w-full',
                'lg:px-0',
                className,
            )}
        >
            <div className='text-3xl font-semibold text-center'>
                Frequently asked questions
            </div>
            <Faq
                className='min-w-full text-gray-800'
                items={[
                    {
                        heading: 'Can i use Salespack for my agency?',
                        content: (
                            <div>
                                Yes! We have a members system that lets you
                                invite members to your org and let them monitor
                                the campaigns analytics and stats.
                                <br />
                                <br />
                                Usually we recommend that you create a separate
                                org for each client.
                            </div>
                        ),
                    },
                    {
                        heading: 'Do you have a free trial?',
                        content: (
                            <div>Yes! All plans have a 7 days free trial.</div>
                        ),
                    },
                    {
                        heading: 'What email accounts can i use?',
                        content: (
                            <div>
                                You can use any email accounts supporting SMTP
                                and IMAP (basically all providers, Google
                                requires some additional steps to enabled SMTP)
                                <br />
                                <br />
                                If you are searching for an email provider, we
                                recommend using Zoho.us, it lets you connect up
                                to 30 domains on a single plan (notice the .us
                                domain, the .eu service is much worse)
                            </div>
                        ),
                    },
                    {
                        heading: 'What is email warmup?',
                        content: (
                            <div className=''>
                                If you enable warmup for your email accounts we
                                will send emails from your account to other
                                Salespack users.
                                <br />
                                <br />
                                This lets you monitor if your emails end up in
                                spam and makes ISPs increase the trust scores of
                                your email accounts.
                                <br />
                                <br />
                                We will also move emails that end up in spam to
                                the main inbox.
                                <br />
                                <br />
                                All warmup emails you receive will be moved to a
                                separate folder to not clutter your inbox.
                            </div>
                        ),
                    },
                    {
                        heading: 'How are page emails counted?',
                        content: (
                            <div className=''>
                                Every email you send in a campaign (counting
                                follow ups) increments your org count.
                                <br />
                                Warmup emails are not counted.
                            </div>
                        ),
                    },

                    {
                        heading: 'Who is behind Salespack?',
                        content: (
                            <span className=''>
                                I am Tommy, a software engineer living in Italy.
                                you can chat with me on{` `}
                                <Link
                                    underline
                                    href={'https://twitter.com/__morse'}
                                >
                                    Twitter
                                </Link>{' '}
                                :).
                            </span>
                        ),
                    },
                ]}
            />
        </PageContainer>
    )
}
